import Swup from 'swup'
import SwupProgressPlugin from '@swup/progress-plugin';
import SwupBodyClassPlugin from '@swup/body-class-plugin';
import SwupScriptsPlugin from '@swup/scripts-plugin';
import SwupHeadPlugin from '@swup/head-plugin';

const linkInclude = [
  `a[href^="${window.location.origin}"]`,
  `a[href^="/"]`,
  `a[href^="#"]`
]

const linkExclude = [
  '[download]',
  '[target="_blank"]',
  '[data-no-swup]',
  `[href$=".pdf"]`,
  `a[href^="/audio"]`
]

const exclude = linkExclude.map(selector => `:not(${selector})`).join('')
const linkSelector = linkInclude.map(include => `${include}${exclude}`).join(',')

window.swup = new Swup( {
  linkSelector,
  plugins: [
    new SwupProgressPlugin(),
    new SwupBodyClassPlugin(),
    new SwupHeadPlugin(),
    new SwupScriptsPlugin()
  ]
})

const interceptClickForComponent = (component) => {
  document.querySelector(component).shadowRoot.addEventListener('click', (event) => {
    if (event.target.tagName.toLowerCase() === 'a') {
      event.preventDefault();
      swup.navigate(event.target.href);
    }
  })
}

const configureNewNielsen = () => {
  const script = document.getElementById('new-nielsen')
  if (script) {
    const configure = (t, n) => {
      t[n] = t[n] || {
        nlsQ: function(e, o, c, r, s, i) {
          return s = t.document,
            r = s.createElement("script"),
            r.async = 1,
            r.src = ("http:" === t.location.protocol ? "http:" : "https:") + "//cdn-gl.imrworldwide.com/conf/" + e + ".js#name=" + o + "&ns=" + n,
            i = s.getElementsByTagName("script")[0],
            i.parentNode.insertBefore(r, i),
            t[n][o] = t[n][o] || {
              g: c || {},
              ggPM: function(e, c, r, s, i) {
                (t[n][o].q = t[n][o].q || []).push([e, c, r, s, i])
              }
            }, t[n][o]
        }
      }
    }

    configure(window, "NOLBUNDLE")

    window.nSdkInstance = NOLBUNDLE.nlsQ("P05CD297D-5D19-4704-A2E4-FACCB1EC0B99", "rnznielsen", {})

    console.log('New Nielsen configured')
  }
}

const loadNewNielsen = () => {
  if (window.nSdkInstance) {
    const staticMetadataObjectNewsShow = {
      type: "static", // Required: the same for every static asset
      assetid: `${window.location.pathname}`, // Required and needs to be unique per asset, can populate the page URL minus the domain
      section: "RNZ - Other", // Required
    };

    window.nSdkInstance.ggPM("staticstart", staticMetadataObjectNewsShow)

    console.log('New Nielsen loaded')
  }
}

const configureChartbeat = () => {
  const config = window._sf_async_config || {};

  config.uid = 65651; // ACCOUNT NUMBER
  config.domain = 'www.radionz.co.nz'; // DOMAIN
  config.flickerControl = false;
  config.useCanonical = true;

  window._sf_async_config = config
  window._sf_startpt = (new Date()).getTime();

  console.log('Chartbeat configured')
}

const loadChartbeat = () => {
  const config = window._sf_async_config || {};

  var paths = window.location.pathname.replace(/^\//,'').split('/');

  if (~['national','international','concert'].indexOf( paths[0].toLowerCase() ) ) {
    paths = paths.slice(0, 3);
  } else {
    paths = paths.slice(0, 2);
  }

  config.sections = paths.join(',');
  config.authors = '';

  var e = document.createElement('script');

  var n = document.getElementsByTagName('script')[0];

  e.setAttribute("id", "chartbeat-script");
  e.type = 'text/javascript';
  e.async = true;
  e.src = '//static.chartbeat.com/js/chartbeat.js';

  n.parentNode.insertBefore(e, n);

  console.log('Chartbeat loaded')
}

const loadShieldedSite = () => {
  var frameName = new ds07o6pcmkorn({
    openElementId: "#shielded-logo",
    modalID: "shielded-site-modal",
  });

  frameName.init();

  console.log('Shielded Site loaded')
}

const unloadElement = (id) => {
  document.getElementById(id)?.remove()
}

const init = () => {
  configureChartbeat()
  configureNewNielsen()
}

const load = () => {
  loadShieldedSite()
  loadChartbeat()
  loadNewNielsen()

  //interceptClickForComponent('rnz-site-header')
  interceptClickForComponent('rnz-site-footer')
}

document.addEventListener("DOMContentLoaded", () => {
  init()
  load()
})

swup.hooks.on('page:view', () => {
  load()
})
